.NavBarCon1 {
  display: flex;
  position: relative;
  width: 100%;
  padding: 6px 6px 16px 6px;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  border-top: solid 0.5px #4d4d4d;

  background: #fff;
  box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.25);
}
.NavBarCon1BlockNav {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.45);
}

.NavBarCon1B {
  display: flex;
  width: 100%;
  max-width: 500px;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  background: #fff;
  margin: 0 auto;
}
.NavBarCon2 {
  display: flex;
  width: 55px;
  height: 34px;
  padding: 2px 4px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.NavBarIconCon {
  height: 16px;
}
.NavBarText {
  color: var(--app-gray-medium, #6d6d6d);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.NavBarTextActive {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
