.NavBarCon1 {
  box-sizing: border-box;
  background: #fff;
  border-top: .5px solid #4d4d4d;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 6px 6px 16px;
  display: flex;
  position: relative;
  box-shadow: 0 -3px 6px #00000040;
}

.NavBarCon1BlockNav {
  background: #00000073;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.NavBarCon1B {
  background: #fff;
  flex: 1 0 0;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
}

.NavBarCon2 {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 55px;
  height: 34px;
  padding: 2px 4px;
  display: flex;
}

.NavBarIconCon {
  height: 16px;
}

.NavBarText {
  color: var(--app-gray-medium, #6d6d6d);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.NavBarTextActive {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
/*# sourceMappingURL=index.64cf2492.css.map */
